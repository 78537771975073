import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-boise-idaho.png'
import image0 from "../../images/cities/scale-model-of-discovery-center-of-idaho-in-boise-idaho.png"
import image1 from "../../images/cities/scale-model-of-world-center-for-birds-of-prey-in-boise-idaho.png"
import image2 from "../../images/cities/scale-model-of-zoo-boise-in-boise-idaho.png"
import image3 from "../../images/cities/scale-model-of-freak-alley-gallery-in-boise-idaho.png"
import image4 from "../../images/cities/scale-model-of-old-idaho-penitentiary-site-in-boise-idaho.png"
import image5 from "../../images/cities/scale-model-of-julia-davis-park-in-boise-idaho.png"
import image6 from "../../images/cities/scale-model-of-idaho-state-capitol-building-in-boise-idaho.png"
import image7 from "../../images/cities/scale-model-of-bogus-basin-recreation-area-in-boise-idaho.png"
import image8 from "../../images/cities/scale-model-of-idaho-state-museum-in-boise-idaho.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Boise'
            state='Idaho'
            image={image}
            lat='43.6150186'
            lon='-116.20231369999999'
            attractions={ [{"name": "Discovery Center of Idaho", "vicinity": "131 W Myrtle St, Boise", "types": ["museum", "point_of_interest", "establishment"], "lat": 43.6085029, "lng": -116.19923790000001}, {"name": "World Center For Birds of Prey", "vicinity": "5668 W Flying Hawk Ln, Boise", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 43.51708989999999, "lng": -116.25633779999998}, {"name": "Zoo Boise", "vicinity": "355 Julia Davis Dr, Boise", "types": ["zoo", "point_of_interest", "establishment"], "lat": 43.6078722, "lng": -116.20290019999999}, {"name": "Freak Alley Gallery", "vicinity": "210 N 9th St, Boise", "types": ["art_gallery", "point_of_interest", "establishment"], "lat": 43.6169535, "lng": -116.20285949999999}, {"name": "Old Idaho Penitentiary Site", "vicinity": "2445 Old Penitentiary Rd, Boise", "types": ["museum", "point_of_interest", "establishment"], "lat": 43.6027935, "lng": -116.16197920000002}, {"name": "Julia Davis Park", "vicinity": "700 S Capitol Blvd, Boise", "types": ["park", "point_of_interest", "establishment"], "lat": 43.6076491, "lng": -116.20153419999997}, {"name": "Idaho State Capitol Building", "vicinity": "700 W Jefferson St, Boise", "types": ["point_of_interest", "local_government_office", "establishment"], "lat": 43.61777499999999, "lng": -116.1995369}, {"name": "Bogus Basin Recreation Area", "vicinity": "2600 N Bogus Basin Rd, Boise", "types": ["point_of_interest", "establishment"], "lat": 43.6433717, "lng": -116.20601369999997}, {"name": "Idaho State Museum", "vicinity": "610 Julia Davis Dr, Boise", "types": ["museum", "point_of_interest", "establishment"], "lat": 43.61066, "lng": -116.20549900000003}] }
            attractionImages={ {"Discovery Center of Idaho":image0,"World Center For Birds of Prey":image1,"Zoo Boise":image2,"Freak Alley Gallery":image3,"Old Idaho Penitentiary Site":image4,"Julia Davis Park":image5,"Idaho State Capitol Building":image6,"Bogus Basin Recreation Area":image7,"Idaho State Museum":image8,} }
       />);
  }
}